import '@fortawesome/fontawesome-free/css/all.min.css';

let toggleBtn = document.querySelector("#navbar-toggle");
let collapse = document.querySelector("#navbar-collapse");

toggleBtn.onclick = () => {
  collapse.classList.toggle("hidden");
  collapse.classList.toggle("flex");
};

// add active class on navbar item if it is the current page
let navbarItems = document.querySelectorAll("#navbar-collapse a");
navbarItems.forEach(item => {
  if (window.location.href === item.href) {
    item.classList.add("active");
    item.classList.remove("nav");
  } else {
    item.classList.remove("active");
    item.classList.add("nav");
  }
} );

// add year to #footer-year
let date = new Date();
let year = date.getFullYear();
let footerYear = document.querySelector("#footer-year");
footerYear.innerHTML = year;